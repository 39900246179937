<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            <div class="mb-n3">Pricing Rule Label Mapping Files</div>
        </grid-header>

        <div class="mt-3 full-height-layout">
            <pricing-rule-label-mapping-files-group
                v-if="!loading"
                environment="staging"
                :mappingFiles="mappingFiles"
            />

            <pricing-rule-label-mapping-files-group
                v-if="!loading"
                environment="production"
                :mappingFiles="mappingFiles"
            />
        </div>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PricingRuleLabelMappingFilesGroup from '@/components/pricing/PricingRuleLabelMappingFilesGroup.vue'

export default {
    name: 'PricingRuleLabels',
    components: { GridHeader, PricingRuleLabelMappingFilesGroup },
    data () {
        return {
            loading: true,
            mappingFiles: [],
        }
    },
    methods: {},
    created () {
        const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABEL_MAPPING_FILES.GET
        this.addJob(url)
        this.loading = true
        this.$api.post(url)
            .then(({ data }) => this.$set(this, 'mappingFiles', data.data))
            .finally(() => {
                this.finishJob(url)
                this.loading = false
            })
    },
}
</script>

<style lang="scss" scoped>
</style>
