<template>
    <mercur-card class="environment mb-3 p-0">
        <div class="environment__head rounded-top">
            <div class="row">
                <div class="col-8 environment__title">
                    {{ environment | beautify }}
                </div>
                <div class="col-4 text-right pricing-rule__actions">
                    <mercur-button
                        v-if="canUpdate()"
                        class="btn btn-success"
                        @click="update()"
                    >
                        <i class="fa fa-check ml-1 mr-1"></i>
                        Save changes
                    </mercur-button>
                    <mercur-button
                        v-if="canClearCache()"
                        class="btn btn-white"
                        @click="clearCache()"
                    >
                        <i class="fa fa-rotate-right ml-1 mr-1"></i>
                        Clear cache
                    </mercur-button>
                </div>
            </div>
        </div>
        <div class="p-3 environment__body">
            <div class="row">
                <div class="col-4 mb-2">
                    <label>Shop prices file:</label>
                    <filedropper
                        :url="getUploadUrl('shopprices')"
                        mimeTypes="text/csv"
                        @uploadUpdate="uploadUpdate('shopprices', $event)"
                    >
                        Drop a file or
                    </filedropper>

                    <template v-if="!loading">
                        <mercur-button
                            v-if="hasFile('shopprices')"
                            class="btn btn-primary btn-download mt-2"
                            @click="downloadFile('shopprices')"
                        >
                            <i class="fa fa-download ml-1 mr-1"></i>
                            Download
                        </mercur-button>
                        <div v-else class="mt-3 text-center text-danger">
                            File not found!
                        </div>
                    </template>
                </div>

                <div class="col-4 mb-2">
                    <label>Price groups file:</label>
                    <filedropper
                        :url="getUploadUrl('pricegroups')"
                        mimeTypes="text/csv"
                        @uploadUpdate="uploadUpdate('pricegroups', $event)"
                    >
                        Drop a file or
                    </filedropper>

                    <template v-if="!loading">
                        <mercur-button
                            v-if="hasFile('pricegroups')"
                            class="btn btn-primary btn-download mt-2"
                            @click="downloadFile('pricegroups')"
                        >
                            <i class="fa fa-download ml-1 mr-1"></i>
                            Download
                        </mercur-button>
                        <div v-else class="mt-3 text-center text-danger">
                            File not found!
                        </div>
                    </template>
                </div>

                <div class="col-4 mb-2">
                    <label>Customer group mappings file:</label>
                    <filedropper
                        :url="getUploadUrl('customergroupmappings')"
                        mimeTypes="text/csv"
                        @uploadUpdate="uploadUpdate('customergroupmappings', $event)"
                    >
                        Drop a file or
                    </filedropper>

                    <template v-if="!loading">
                        <mercur-button
                            v-if="hasFile('customergroupmappings')"
                            class="btn btn-primary btn-download mt-2"
                            @click="downloadFile('customergroupmappings')"
                        >
                            <i class="fa fa-download ml-1 mr-1"></i>
                            Download
                        </mercur-button>
                        <div v-else class="mt-3 text-center text-danger">
                            File not found!
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import Filedropper from '@/components/utils/Filedropper.vue'

export default {
    name: 'PricingRuleLabelMappingFilesGroup',
    components: { Filedropper },
    props: {
        environment: {
            required: true,
        },
        mappingFiles: {
            required: true,
        },
    },
    data () {
        return {
            loading: false,
            isDirty: false,
        }
    },
    methods: {
        getUploadUrl (fileType) {
            return CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABEL_MAPPING_FILES.CSV_UPLOAD_URL
                .replace('{environment}', this.environment)
                .replace('{fileType}', fileType)
        },
        uploadUpdate (fileType, $event) {
            if ($event.type === 'completed') {
                this.$root.$emit('notification:global', {
                    message: 'File uploaded successfully.',
                    type: 'info',
                })

                this.createOrUpdateFile(fileType, $event.file.objectName)
                this.isDirty = true
            }
        },
        hasFile (fileType) {
            return this.mappingFiles.some(file => file.environment === this.environment && file.fileType === fileType)
        },
        hasEveryFile () {
            return this.mappingFiles.filter(file => file.environment === this.environment).length === 3
        },
        getFile (fileType) {
            return this.mappingFiles.find(file => file.environment === this.environment && file.fileType === fileType)
        },
        createOrUpdateFile (fileType, fileName) {
            const mappingFile = this.getFile(fileType)
            if (mappingFile) {
                mappingFile.fileName = fileName

                const index = this.mappingFiles.findIndex(file => file.pricingRuleLabelMappingFileId === mappingFile.pricingRuleLabelMappingFileId)
                this.mappingFiles.splice(index, 1, mappingFile)

                return
            }

            this.mappingFiles.push({
                environment: this.environment,
                fileType: fileType,
                fileName: fileName,
            })
        },
        canUpdate () {
            return this.isDirty && this.hasEveryFile()
        },
        update () {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABEL_MAPPING_FILES.UPDATE
                .replace('{environment}', this.environment)

            this.addJob(url)
            this.loading = true
            this.$api.post(url, { files: this.mappingFiles.filter(file => file.environment === this.environment) })
                .then(() => {
                    this.$root.$emit('notification:global', {
                        message: 'Changes saved successfully.',
                        type: 'success',
                    })
                    this.isDirty = false
                })
                .finally(() => {
                    this.finishJob(url)
                    this.loading = false
                })
        },
        downloadFile (fileType) {
            const mappingFile = this.getFile(fileType)
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABEL_MAPPING_FILES.DOWNLOAD_CSV
                .replace('{environment}', this.environment)
                .replace('{fileType}', fileType)
            const payload = {
                name: mappingFile.fileName,
                saveAsName: `${this.environment}-${fileType}-${mappingFile.fileName}`,
            }

            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: 'Download pricing CSV',
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: false,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: 'Download started.',
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: 'Downloading failed. Please try again.',
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        canClearCache () {
            return !this.isDirty && this.hasEveryFile()
        },
        clearCache () {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_LABEL_MAPPING_FILES.CLEAR_CACHE
                .replace('{environment}', this.environment)

            this.addJob(url)
            this.loading = true
            this.$api.post(url)
                .then(() => {
                    this.$root.$emit('notification:global', {
                        message: 'Cache cleared successfully.',
                        type: 'success',
                    })
                })
                .finally(() => {
                    this.finishJob(url)
                    this.loading = false
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables.scss';
.environment {
    &__head {
        background-color: $dark-color;
        padding: 5px 15px;
    }

    &__title {
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        margin: 0;

        i {
            font-size: 18px !important;
            margin-left: 5px;

            cursor: pointer;
        }
    }

    &__actions {
        align-items: center;
        justify-content: flex-end;
        display: flex;
    }

    &__info {
        font-size: 11px;
        color: #cccccc;
        margin-left: 25px;
    }

    button {
        height: 32px;
        line-height: normal;
    }

    .btn-download {
        width: 100%;
    }
}
</style>
